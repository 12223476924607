<template>
    <!-- 运输单核算 -->
    <div class="business-accounting " @click.prevent="clearHover">
        <div class="businessAccountingBox" ref="waiBox">
            <div ref="topBox" class="query-criteria">
                <div>
                    <p>运输单编号:</p>
                    <el-input :title="ysdbh" v-model="ysdbh" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>运输单状态:</p>
                    <el-select v-model="ysdzt" placeholder="请选择">
                        <el-option
                            v-for="item in ysdztList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <p>任务单编号:</p>
                    <el-input v-model="rwdbh" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>强度等级:</p>
                    <el-input v-model="ysdw" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>附加型号:</p>
                    <el-input v-model="fjxh" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>工程名称:</p>
                    <el-input v-model="gcmc" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>客户名称:</p>
                    <el-input v-model="khmc" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>结算状态:</p>
                    <el-select v-model="hszt" placeholder="请选择">
                        <el-option
                            v-for="item in jsztList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <p>发货时间段:</p>
                    <el-date-picker
                        v-model="fhTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </div>
                <div>
                    <p>签收时间段:</p>
                    <el-date-picker
                        v-model="qsTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </div>
                <div>
                    <el-button @click="getHeSuanList('queryList')" type="primary">
                        查询
                    </el-button>
                </div>
            </div>
            <div ref="centerBtn">
                <el-button
                    class="bulk-send"
                    type="primary"
                    size="small"
                    @click="bulkSend"
                >
                    批量核算
                </el-button>
            </div>
            <div ref="bottomTable" class="table-list">
                <el-table
                    :data="tableData"
                    style="width: 100%"
                    height="100%"
                    stripe
                    ref="tableRefTwo"
                    show-summary
                    v-loading="listLoading"
                    header-row-class-name="tableHeader"
                    @selection-change="handleSelectionChange"
                    @row-click="getClickLine"
                    :summary-method="getSum"
                >
                    <el-table-column
                        label="选择"
                        type="selection"
                        width="60"
                    >
                    </el-table-column>
                    <el-table-column
                        width="200"
                        label="运输单编号"
                        show-overflow-tooltip
                    >
                        <template slot-scope="scope">
                            <p @click="awakenAlert(scope.row)" class="yunshuNumber">
                                {{ scope.row.delivery_receipt_number }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="运输单状态"
                        width="100"
                        show-overflow-tooltip
                    >
                        <template slot-scope="scope">
                            <el-popover
                                v-model="scope.row.show"
                                placement="right"
                                trigger="focus"
                                popper-class="business-accounting-popover"
                            >
                                <div class="click-look-details-check" :data="scope.row">
                                    <div>
                                        <p>运输单编号</p>
                                        <el-tooltip :enterable="false" :content="scope.row.delivery_receipt_number" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.delivery_receipt_number }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>运输单状态</p>
                                        <p>{{ scope.row.delivery_receipt_status | spanFilter }}</p>
                                    </div>
                                    <div>
                                        <p>任务单编号</p>
                                        <el-tooltip :enterable="false" :content="scope.row.task_number" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.task_number }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>工程名称</p>
                                        <el-tooltip :enterable="false" :content="scope.row.engineering_name" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.engineering_name }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>客户名称</p>
                                        <p>{{ scope.row.client_name }}</p>
                                    </div>
                                    <div>
                                        <p>站点</p>
                                        <p>{{ scope.row.station_name }}</p>
                                    </div>
                                    <div>
                                        <p>强度等级</p>
                                        <p>{{ scope.row.strength_grade_name }}</p>
                                    </div>
                                    <div>
                                        <p>附加型号</p>
                                        <p>{{ scope.row.special_require_name }}</p>
                                    </div>
                                    <div>
                                        <p>任务车次</p>
                                        <p>{{ scope.row.task_trains }}</p>
                                    </div>
                                    <div>
                                        <p>车牌号</p>
                                        <p>{{ scope.row.plate_number }}</p>
                                    </div>
                                    <div>
                                        <p>发货时间</p>
                                        <p>{{ scope.row.deliver_time }}</p>
                                    </div>
                                    <div>
                                        <p>发货方量</p>
                                        <p>{{ scope.row.deliver_volume }}</p>
                                    </div>
                                    <div>
                                        <p>剩余方量</p>
                                        <p>{{ scope.row.surplus_volume }}</p>
                                    </div>
                                    <div>
                                        <p>退货方量</p>
                                        <p>{{ scope.row.back_volume }}</p>
                                    </div>
                                    <div>
                                        <p>签收时间</p>
                                        <p>{{ scope.row.sign_time }}</p>
                                    </div>
                                    <div>
                                        <p>签收方量</p>
                                        <p>{{ scope.row.sign_volume }}</p>
                                    </div>
                                    <div>
                                        <p>调整后签收方量</p>
                                        <p>{{ scope.row.adjusted_sign_volume }}</p>
                                    </div>
                                    <div>
                                        <p>砼单价</p>
                                        <p>{{ scope.row.concrete_price }}</p>
                                    </div>
                                    <div>
                                        <p>砼金额</p>
                                        <p>{{ scope.row.concrete_amount_money }}</p>
                                    </div>
                                    <div>
                                        <p>调整后砼单价</p>
                                        <p>{{ scope.row.adjusted_concrete_price }}</p>
                                    </div>
                                    <div>
                                        <p>调整后砼金额</p>
                                        <p>{{ scope.row.adjusted_concrete_amount_money }}</p>
                                    </div>
                                    <div>
                                        <p>盈亏方量</p>
                                        <p>{{ scope.row.overage_volume }}</p>
                                    </div>
                                    <div>
                                        <p>调整后盈亏</p>
                                        <p>{{ scope.row.adjusted_overage_volume }}</p>
                                    </div>
                                    <div>
                                        <p>浇筑方式</p>
                                        <p>{{ scope.row.pouring_name }}</p>
                                    </div>
                                    <div>
                                        <p>泵送高度</p>
                                        <p>{{ scope.row.pump_height }}</p>
                                    </div>
                                    <div>
                                        <p>泵送方量</p>
                                        <p>{{ scope.row.pump_volume }}</p>
                                    </div>
                                    <div>
                                        <p>调整后泵送方量</p>
                                        <p>{{ scope.row.adjusted_pump_volume }}</p>
                                    </div>
                                    <div>
                                        <p>非泵方量</p>
                                        <p>{{ scope.row.non_pump_volume }}</p>
                                    </div>
                                    <div>
                                        <p>调整后非泵方量</p>
                                        <p>{{ scope.row.adjusted_non_pump_volume }}</p>
                                    </div>
                                    <div>
                                        <p>泵送金额</p>
                                        <p>{{ scope.row.pump_money }}</p>
                                    </div>
                                    <div>
                                        <p>调整后泵送金额</p>
                                        <p>{{ scope.row.adjusted_pump_money }}</p>
                                    </div>
                                    <div>
                                        <p>砂浆方量</p>
                                        <p>{{ scope.row.mortar_volume }}</p>
                                    </div>
                                    <div>
                                        <p>砂浆调整后方量</p>
                                        <p>{{ scope.row.adjusted_mortar_volume }}</p>
                                    </div>
                                    <div>
                                        <p>砂浆单价</p>
                                        <p>{{ scope.row.mortar_price }}</p>
                                    </div>
                                    <div>
                                        <p>砂浆金额</p>
                                        <p>{{ scope.row.mortar_amount_money }}</p>
                                    </div>
                                    <div>
                                        <p>调整后砂浆单价</p>
                                        <p>{{ scope.row.adjusted_mortar_price }}</p>
                                    </div>
                                    <div>
                                        <p>调整后砂浆金额</p>
                                        <p>{{ scope.row.adjusted_mortar_amount_money }}</p>
                                    </div>
                                    <div>
                                        <p>运输单位</p>
                                        <p>{{ scope.row.traffic_unit }}</p>
                                    </div>
                                    <div>
                                        <p>运距</p>
                                        <p>{{ scope.row.haul_distance }}</p>
                                    </div>
                                    <div>
                                        <p>运费</p>
                                        <p>{{ scope.row.freight }}</p>
                                    </div>
                                    <div>
                                        <p>调整后运费</p>
                                        <p>{{ scope.row.adjusted_freight }}</p>
                                    </div>
                                    <div>
                                        <p>其他费用</p>
                                        <p>{{ scope.row.other_fees }}</p>
                                    </div>
                                    <div>
                                        <p>调整原因</p>
                                        <p>{{ scope.row.adjusted_reason }}</p>
                                    </div>
                                    <div>
                                        <p>小计金额</p>
                                        <p>{{ scope.row.subtotal }}</p>
                                    </div>
                                    <div>
                                        <p>调整后小计金额</p>
                                        <p>{{ scope.row.adjusted_subtotal }}</p>
                                    </div>
                                    <div>
                                        <p>结算状态</p>
                                        <p>{{ scope.row.check_status_name }}</p>
                                    </div>
                                </div>
                                <span style="cursor:pointer;color:#333333" slot="reference">
                                    {{ scope.row.delivery_receipt_status | spanFilter }}
                                </span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="task_number"
                        label="任务单编号"
                        show-overflow-tooltip
                        width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="engineering_name"
                        label="工程名称"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="client_name"
                        label="客户名称"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="station_name"
                        label="站点"
                        width="60"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="strength_grade_name"
                        label="产品标号"
                        width="80"
                        show-overflow-tooltip
                    >
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.strength_grade_name }}{{ scope.row.special_require_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="task_trains"
                        label="任务车次"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="plate_number"
                        label="车牌号"
                        width="100"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="deliver_time"
                        label="发货时间"
                        width="160"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="deliver_volume"
                        label="发货方量"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="adjusted_sign_volume"
                        label="签收方量"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="concrete_price"
                        label="砼单价"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="adjusted_concrete_price"
                        label="砼金额"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="freight"
                        label="运费"
                        width="80"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="subtotal"
                        label="小计金额"
                        width="100"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
            </div>
            <div class="data-display">
                <div class="el_left multipleSelected">
                    已选 <span>{{ selectList.length }}</span> 条
                </div>
                共<span>{{ listLength }}</span>条数据，已加载<span>{{ loaded }}</span>条
            </div>
        </div>
    </div>
</template>

<script>
// 运输单核算
export default {
    data() {
        return {
            tableData: [],
            ysdztList: [
                {
                    value: '',
                    label: '请选择',
                },
                {
                    value: '1',
                    label: '已排(未接单)',
                }, {
                    value: '2',
                    label: '作废',
                }, {
                    value: '3',
                    label: '完成',
                }, {
                    value: '5',
                    label: '已接单',
                }, {
                    value: '6',
                    label: ' 预警(未结单)',
                }, {
                    value: '7',
                    label: ' 冻结(未结单)',
                }, {
                    value: '8',
                    label: '打料开始',
                }, {
                    value: '9',
                    label: '打料完成',
                },
            ],
            jsztList: [
                {
                    value: '',
                    label: '请选择',
                },
                {
                    value: '3',
                    label: '未核算',
                }, {
                    value: '4',
                    label: '已核算',
                }, {
                    value: '5',
                    label: '调整后核算',
                },
            ],
            ysdbh: '',
            ysdzt: '',
            rwdbh: '',
            fhTime: '',
            gcmc: '',
            khmc: '',
            qsTime: '',
            ysdw: '',
            hszt: '',
            fjxh: '',
            loaded: 0,
            tableDom: {},
            count: 1,
            listLoading: false,
            listLength: 0,
            allData: false,
            selectList: [],
        };
    },
    mounted() {
        this.tableDom = this.$refs.tableRefTwo.bodyWrapper;
        // this.setBoxHeight();
        this.getHeSuanList();
        this.tableDom.addEventListener('scroll', () => {
            // 滚动距离
            const scrollTop = Math.floor(this.tableDom.scrollTop);
            // 变量windowHeight是可视区的高度
            const windowHeight = this.tableDom.clientHeight || this.tableDom.clientHeight;
            // 变量scrollHeight是滚动条的总高度
            const scrollHeight = this.tableDom.scrollHeight || this.tableDom.scrollHeight;
            // eslint-disable-next-line max-len
            if (scrollTop + windowHeight === scrollHeight || scrollTop + windowHeight === scrollHeight + 1 || scrollTop + windowHeight === scrollHeight - 1) {
                // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
                if (!this.allData) {this.getMoreLog();}

            }
        });
    },
    filters: {
        // eslint-disable-next-line consistent-return
        spanFilter(value) {
            switch (value) {
                case 1:
                    return '已排(未接单)';
                case 2:
                    return '作废';
                case 3:
                    return '完成';
                case 4:
                    return '退砼';
                case 5:
                    return '已接单';
                case 6:
                    return '预警(未结单)';
                case 7:
                    return '冻结(未结单)';
                case 8:
                    return '打料开始';
                case 9:
                    return '打料完成';
            }
        },
    },
    methods: {
        // 合计行方法
        getSum(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;

                    }, 0);
                    if (column.label === '车牌号') {
                        sums[index] = '';
                    } else if (column.label === '任务车次') {
                        sums[index] = '';
                    } else if (column.label === '附加型号') {
                        sums[index] = '';
                    } else if (column.label === '砼单价') {
                        sums[index] = '';
                    } else {
                        sums[index] = sums[index].toFixed(2);
                    }
                } else {
                    sums[index] = ' ';
                }
            });
            return sums;
        },
        // 获取点击行
        getClickLine(row, column, event) {
            event.stopPropagation();
            this.tableData.forEach(item => {
                item.show = false;
            });
            this.hoverLineData = row;
            row.show = true;
        },
        // 清除滑动
        clearHover(event) {
            event.preventDefault();
            event.stopPropagation();
            this.clearAlertShow();
        },
        // 清除点击方法
        clearAlertShow() {
            this.tableData.forEach(item => {
                item.show = false;
            });
        },
        // 唤醒弹窗
        awakenAlert(key) {
            event.stopPropagation();
            this.clearAlertShow();
            this.$HsAndHdLayer('FormCode=xs_accounts_ysd_hs', key.id, '核算', 'he');
        },
        // 获取盒子高度
        setBoxHeight() {
            const aH = this.$refs.topBox.offsetHeight;
            const bH = this.$refs.centerBtn.offsetHeight;
            const cH = this.$refs.bottomTable.offsetHeight;
            const dH = Number(this.$refs.tableRefTwo.height);
            this.$refs.waiBox.style = `height:${(aH + bH + cH + dH)}px;`;
        },
        // 上拉加载
        getMoreLog() {
            const listLength = Number(this.tableData.length) - 1;
            if (listLength < this.listLength) {
                this.listLoading = true;
                this.getHeSuanList('pullUp');
            } else {
                this.allData = true;
            }
        },
        // 列表多选
        handleSelectionChange(value) {
            this.selectList = value;
        },
        // 批量发送
        bulkSend() {
            const idList = [];
            this.selectList.forEach(item => {
                if (item.id) {
                    idList.push(item.id);
                }
            });
            const obj = {
                ids: idList,
                status: 4,
            };
            this.$axios
                .put('/interfaceApi/sale/accounts/delivery_receipt_batch_check', obj)
                .then(() => {
                    this.listLoading = false;
                    this.$message.success('发送成功');
                    this.getHeSuanList('queryList');
                })
                .catch(error => {
                    this.listLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取核算列表
        getHeSuanList(status) {
            if (status === 'pullUp') {
                this.count++;
            } else if (status === 'queryList') {
                this.count = 1;
            }
            this.$axios
                .post('/interfaceApi/sale/accounts/delivery_calculate_page', {
                    delivery_receipt_number: this.ysdbh,
                    task_number: this.rwdbh,
                    delivery_receipt_status: this.ysdzt,
                    check_status: this.hszt,
                    engineering_name: this.gcmc,
                    client_name: this.khmc,
                    sign_time_start: this.qsTime ? this.qsTime[0] : '',
                    sign_time_end: this.qsTime ? this.qsTime[1] : '',
                    deliver_time_start: this.fhTime ? this.fhTime[0] : '',
                    deliver_time_end: this.fhTime ? this.fhTime[1] : '',
                    strength_grade_name: this.ysdw,
                    special_require_name: this.fjxh,
                    pageindex: this.count,
                    pagesize: 20,
                })
                .then(res => {
                    this.listLoading = false;
                    this.listLength = res.total;
                    let dataLength = 20 * this.count;
                    this.loaded = dataLength > this.listLength ? dataLength = this.listLength : dataLength;
                    if (status === 'queryList') { // 查询
                        this.allData = false;
                        this.tableDom.scrollTop = 0;
                        this.tableData = [];
                    }
                    const newList = this.tableData.concat(res.rows);
                    this.tableData = newList;
                    this.$nextTick(() => {
                        this.$refs.tableRefTwo.doLayout();
                    });
                })
                .catch(error => {
                    this.listLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.businessAccountingBox
    background #fff
    width 100%
    padding .14rem
    padding-bottom 0
    height 100%
    display flex
    flex-direction column
    overflow-y auto
    .query-criteria
        display flex
        align-items center
        flex-wrap wrap
        height 0.95rem
        border-bottom 1px solid #E7E7E7
        >div
            display flex
            align-items center
            margin-bottom 0.1rem
            margin-right: .1rem;
            p
                min-width 1rem
                font-size .16rem
                color #8593A7
            .el-input
                width: 1.3rem;
                input
                    padding-left .1rem
            .el-select
                width 2rem
            .el-date-editor
                width 3rem
            .el-button
                background #2978ff
                color #fff
                width .9rem
                height .35rem
                display flex
                align-items: center;
                justify-content: center;
                border-color #2978ff
                span
                    line-height 0
    .bulk-send
        color #fff
        font-size .16rem
        margin .1rem 0
        padding 0 0.15rem
        height 0.36rem
    .table-list
        height calc(100% - 2.1rem)
        .yunshuNumber
            cursor:pointer;
            color blue
            text-decoration underline
        .el-table__header-wrapper
            border-left: 1px solid #f3f3f3;
            border-right: 1px solid #f3f3f3;
        .el-table__row>td
            border none
        .el-table::before//去掉最下面的那一条线
            height 0px
        .el-table
                overflow visible !important
                th,td
                    text-align center
                    height .36rem
                    padding 0
                .tableHeader
                    height 0.4rem
                    th
                        background #EDF0F5
                        color #022782
                .el-table__body-wrapper
                    border-left 1px solid #f3f3f3
                    border-right 1px solid #f3f3f3
                    .cell
                        color #404652
                        span
                            color #1577D2
                .el-table__footer-wrapper
                    height .4rem
                    border-left 1px solid #f3f3f3
                    border-right 1px solid #f3f3f3
                    td,th
                        background #FEF9E4
                        border none
                        div
                            color #FF6600 !important
                            font-weight 800
    .data-display
        width 100%
        display flex
        justify-content flex-end
        font-size .14rem
        color #979EA7
        height .5rem
        align-items center
        letter-spacing .02rem
        span
            font-size .18rem
            font-weight 800
            margin-top -.02rem
        span:first-child
            color #2978ff
        span:last-child
            color #D40004
        .multipleSelected
            color #979ea7
            font-size 0.13rem
            line-height 0.26rem
            margin-right 0.2rem
            span
                color #d40004
                font-size 0.18rem
                padding 0 0.1rem
.business-accounting-popover
        width 12.6rem !important;
        .table-in-table-span
            cursor:pointer;
            color:#333333 !important
        .click-look-details-check
            display flex
            flex-wrap wrap
            div
                display flex
                width 4rem
                height .4rem
                border 1px solid #E7E7E7
                margin 0 .05rem .05rem 0
                p:first-child
                    width 1.7rem
                    background #F2F6FC
                    color #022782
                    display flex
                    align-items center
                    padding 0 .2rem
                    font-weight 800
                    font-size: .14rem;
                p:last-child
                    width 2.3rem
                    font-size .14rem
                    padding .2rem
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow:ellipsis
                    margin-top: -0.12rem;
</style>
